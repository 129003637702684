.Label {
	color: $grey-color;
	font-size: 0.8em;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	@include bp(medium) {
		font-size: 0.666667em;
	}
	@include bp(larger) {
		font-size: 0.6em;
	}
}

.Label--heading {
	color: inherit;
	@include bp(larger) {
		font-size: 0.666667em;
	}
}

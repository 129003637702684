.Content {
	padding: 0 scale(0.375em);
	@include bp(small) {
		margin-right: unit(1, 6);
	}
	@include bp(large) {
		margin-right: unit(1, 4);
	}
	@include bp(larger) {
		margin-right: unit(1, 3);
	}

	& + & {
		margin-top: scale(1em);
	}
}

.Content--formatted {
	font-style: italic;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		text-transform: uppercase;

		& + * {
			margin-top: 0;
		}
	}

	p {
		hyphens: auto;
	}

	a {
		font-weight: 700;
		background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 92.3076923076%, rgb(68,68,68) 92.3076923076%);
		text-decoration: none;
	}

	> small {
		font-size: 0.8em;
		@include bp(medium) {
			font-size: 0.666667em;
		}
		@include bp(larger) {
			font-size: 0.6em;
		}
	}

	> * + * {
		margin-top: scale(1em);
	}
}

.Content--indented {
	padding-left: scale(0.5em);
}

.Content--shifted {
	@include bp(small) {
		margin-left: scale(-0.25em);
		padding-left: 0;
		padding-right: scale(0.25em);
	}
}

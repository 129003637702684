.Icon {
	position: relative;
	display: inline-block;
	vertical-align: top;
	line-height: 1;
	width: 1em;
	height: 1em;

	> svg {
		display: block;
		width: 100%;
		height: 100%;
		fill: currentColor;
	}
}

.Icon--menu > span {
	top: 50%;
	left: 0;
	right: 0;
	height: 0.125em;
	width: 1.25em;
	margin: -0.125em auto 0;
	@include bp(medium) {
		margin-top: -0.075em;
		height: 0.1em;
		width: 1em;
	}

	&,
	&::before,
	&::after {
		position: absolute;
		border-top: 0.125em solid;
		height: 0;
		width: 100%;
	}

	&::before,
	&::after {
		content: "";
		left: 0;
	}

	&::before {
		top: -0.5em;
	}

	&::after {
		bottom: -0.375em;
	}
}

.Icon--next,
.Icon--prev {

	&::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: scale(-0.25em);
		border-color: rgba(0,0,0,0);
		border-style: solid;
		border-width: scale(0.25em) scale(0.4em) scale(0.25em) scale(0.4em);
	}
}

.Icon--prev {

	&::after {
		margin-left: scale(-0.6em);
		border-right-color: inherit;
	}
}

.Icon--next {

	&::after {
		margin-left: scale(-0.2em);
		border-left-color: inherit;
	}
}

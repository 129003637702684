.AudioPlayer {
	position: relative;
	background-color: $primary-color;
}

.AudioPlayer-visual {
	position: relative;
	z-index: 0;
}

.AudioPlayer-player {

	.AudioPlayer--poster & {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}

	.mejs__container {
		background-color: transparent;
	}
}

.Menu {
	padding-left: scale(0.5em);
}

.Menu-body {
	text-align: right;
	@include bp(small) {
		float: right;
	}
}

.Menu-tiles {
	@include clearfix;
	margin: 0;
	padding: 0;
	display: inline-block;
	vertical-align: top;
}

.Menu-tile {
	display: block;
	list-style: none;
	float: left;

	&.is-active {

		.Menu-label {

		}
	}
}

.Menu-item {
	display: block;
	color: inherit;
	padding: scale(0.4166665em) scale(0.5em);
	text-decoration: none;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	@include bp(medium) {
		padding-top: scale(0.25em);
		padding-bottom: scale(0.25em);
	}
	@include bp(larger) {
		padding-top: scale(0.2em);
		padding-bottom: scale(0.2em);
	}

	&:focus {
		outline: 0;
	}

	&:hover {
		color: $grey-color;
	}
}

.Menu-label {
	display: block;
	padding-left: scale(0.5em, 0.8);
	font-size: 0.8em;
	line-height: scale(1, 0.8);
	letter-spacing: 0.05em;
	text-transform: uppercase;
	@include bp(medium) {
		font-size: 0.666667em;
		line-height: scale(0.9375);
	}
	@include bp(larger) {
		font-size: 0.6em;
		line-height: scale(1);
	}

	&::before {
		content: ">";
		display: block;
		float: left;
		margin-left: scale(-0.5em, 0.8);
	}

	.Menu-item:focus &,
	.Menu-item:active & {

	}

	.Menu-item:active & {

	}
}

.Masthead {
	position: relative;
	z-index: 1;
	margin-left: scale(0.5em);
	@include bp(small) {
		margin-left: scale(0.25em);
	}
}

.Masthead-logo {
	display: block;
	@include bp(small) {
		padding-top: scale(0.95em);
	}
	@include bp(medium) {
		padding-top: scale(0.55em);
	}
}

.TextBlock {
	margin-left: scale(0.75em);
	@include bp(small) {
      margin-left: scale(0.5em);
	}
}

.TextBlock-body {
	padding-right: scale(0.5em);
	margin-left: scale(-0.25em);
	@include bp(small) {
		margin-right: unit(1, 6);
		padding-right: scale(0.75em);
	}
	@include bp(large) {
		margin-right: unit(1, 4);
	}
	@include bp(larger) {
		margin-right: unit(1, 3);
	}
}

.TextBlock--green,
.TextBlock--grey,
.TextBlock--surface,
.TextBlock--white {
	padding-top: scale(0.25em);
	padding-bottom: scale(1.25em);
}

.TextBlock--green {
	background-color: $secondary-color;
}

.TextBlock--grey {
	background-color: $light-grey-color;
}

.TextBlock--white {
	padding-top: 0;
	padding-bottom: 0;

	&.TextBlock--surface {
		padding-top: scale(0.25em);
		padding-bottom: scale(1.25em);
	}
}

.TextBlock--aside {
	color: $grey-color;
	text-align: right;

	.TextBlock-body {
		margin-right: 0;
		@include bp(small) {
			margin-left: 0;
			padding-right: scale(0.5em);
		}
		@include bp(large) {
			padding-right: 0;
		}
	}
}

.TextBlock--surface {
	box-shadow: scale(-0.066666em) scale(-0.066666em) scale(0.166666em) rgba(0,0,0,0.4);

	&.TextBlock--reserved,
	&.TextBlock--white {
		background-color: rgb(255,255,255);
	}

	.TextBlock-body {
		padding-left: scale(0.5em);
		@include bp(small) {
			margin-left: 0;
			padding-right: scale(0.5em);
		}
	}
}

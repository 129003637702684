.Header {

}

.Header-heading,
.Header-intro,
.Header-meta {

	* + & {
		margin-top: scale(0.5em);
	}
}

.Header-heading,
.Header-meta {
	margin-left: scale(0.5em);
	@include bp(small) {
		margin-left: scale(0.25em);
	}
}

.Header-next {
	float: right;

	& + * {
		margin-top: 0;
	}
}

.theme-kulturwandel {

	.Link--major {
		display: flex;
		//line-height: scale(1);
	}

    /*
	.Page--home {
		.LinkList-item--category {
			&:not(:first-child) {
				margin-top: scale(0.2em);
			}
			&:not(:last-child) {
				margin-bottom: scale(0.2em);
			}
		}
	}
    */

	.Page--home,
	.Page--medium,
    .Page--gutePraxis {
		.LinkList-item--category {
			.Link--major {
				&:before {
					content: '»';
					font-weight: 400;
					margin-right: scale(0.2em);

					@include bp(medium) {
                      font-weight: 500;
					}
				}
			}
		}
	}

	.Page--article {
		.Hero-heading .Heading-secondary,
		.Page-relatedCaseStudy .Heading-primary {
			display: flex;

            @include bp(medium) {
              line-height: scale(1);
            }

			&:before {
				content: '»';
				font-weight: 400;
				margin-right: scale(0.2em);

				@include bp(medium) {
                    font-weight: 500;
				}
			}
		}
	}

	.Page--gutePraxis {
      .Page-categories {
        margin-top: 0;
      }
	}

	.Page--article .Page-cover,
	.Page--single .Page-header,
	.Page--medium .Page-header,
	.Page--gutePraxis .Page-header {
		.Heading-primary:first-child {
			a {
				background: $secondary-color;

				@include bp(small) {
					margin-left: scale(-0.25em);
					padding-left: scale(0.25em);
					padding-right: scale(0.25em);
				}

			}
		}
	}

	.Page--article .Page-cover h2 + h1,
	.Page--single .Page-header h2 + h1,
	.Page--medium .Page-header h2 + h2,
	.Page--gutePraxis .Page-header h2 + h1 {
		padding-top: .466666em;
		margin-top: .3777em;
		position: relative;

		@include bp(medium) {
			padding-top: .375em;
			margin-top: .375em;
		}

		@include bp(larger) {
			padding-top: .333333em;
			margin-top: .333333em;
		}

		&::after {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			border-top: 1px solid;
			content: "";
			@include bp(small) {
				left: 0.1em;
			}
		}


		.Hero-next {
			font-size: inherit;
			.Button--major {
				font-size: inherit;
			}
		}
	}

	.Page--medium {
		.Heading-secondary {
			margin-top: scale(0.66em);

			@include bp(medium) {
				margin-top: scale(0.57em);
			}

			@include bp(larger) {
				margin-top: scale(0.5em);
			}
		}

		.Block + .Block {
			margin-top: scale(0.5em);
		}
	}

}

.Dots {
	@include clearfix;
	display: inline-block;
}

.Dots-item {
	display: block;
	float: left;
}

.Dots-dot {
	display: block;
	padding: scale(0.25em);
	background-color: inherit;
	background-image: none;
	border: none;
	font-family: inherit;

	&:focus,
	&:active {
		outline: 0;
	}

	&:focus,
	&:active,
	&:hover,
	.isActive & {

		&::after {
			background-color: $primary-color;
		}
	}

	&::after {
		content: "";
		display: block;
		border-radius: scale(0.125em);
		border-style: solid;
		border-width: 1px;
		width: scale(0.25em);
		height: scale(0.25em);
	}
}

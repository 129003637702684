.Link {
	outline: 0;
	transition-duration: 280ms;
	transition-property: color;
	transition-timing-function: cubic-bezier(0.4,0,0.2,1);
	-webkit-tap-highlight-color: rgba(0,0,0,0);

	&:hover {
		color: $grey-color;
	}

	&:active {
		transition-duration: 0ms;
	}
}

.Link--major {
	@include font-titling;
	font-size: 1.5em;
	line-height: scale(0.875);
	text-transform: uppercase;
	@include bp(medium) {
		font-size: 1.75em;
	}
	@include bp(larger) {
		font-size: 2em;
	}

	&.Logo {
		display: inline-flex;
		flex-wrap: wrap;

		svg {
			margin-right: 10px;
            font-size: 0.75em;

			+ span {
				transform: translateY(-3%);
                font-size: 0.75em;
			}
		}
	}

	.SmallCopy {
        letter-spacing: 0.05em;
		font-weight: 400;
		font-family: "NationalWeb", sans-serif;
		width: 100%;
		padding-bottom: 0.2em;
        font-size: 14px;

        @include bp(medium) {
          font-size: 16px;
        }

        @include bp(larger) {
          font-size: 20px;
        }
	}
}

.Link--category {
	margin-left: 0.75em;
	position: absolute;
	top: -2em;
	text-transform: uppercase;
	font-size: 14px;
	letter-spacing: 0.05em;

	@include bp(medium) {
		font-size: 16px;
	}

	@include bp(larger) {
		font-size: 20px;
	}
}

.Link--mixed {
	text-transform: none;
}

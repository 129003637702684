.Article {

}

.Article-block {

	& + & {
		margin-top: scale(0.5em);
	}

	& + .Article-block--chapter {
		margin-top: 0;
	}

	& + .Article-block--aside,
	.Article-block--aside + & {
		margin-top: 0;
	}

	.Article-block--headline + & {
		position: relative;
		margin-top: scale(-0.2916654514em);
		@include bp(medium) {
			margin-top: scale(-0.3124980469em);
		}
		@include bp(larger) {
			margin-top: scale(-0.374998125em);
		}
	}

	&.Article-block--videoPlayer {
		&:first-child {
			margin-top: scale(-0.2916654514em);
			@include bp(medium) {
				margin-top: scale(-0.3124980469em);
			}
			@include bp(larger) {
				margin-top: scale(-0.374998125em);
			}
		}
	}
}

.Article-chapter-label {
	display: none;
}

.Article-figure {
	margin-left: scale(0.5em);
	background-color: $light-grey-color;
	box-shadow: scale(-0.066666em) scale(-0.066666em) scale(0.166666em) rgba(0,0,0,0.4);
}

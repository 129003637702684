body {
	@include font-body;
	font-size: 112.5%;
	line-height: scale(1);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	@include bp(medium) {
		font-size: 150%;
	}
	@include bp(larger) {
		font-size: 187.5%;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
}

p {
	margin: 0;
}

ul,
ol {
	margin: 0;
	padding: 0;
	list-style: none;
}

a {
	color: inherit;
	outline: 0;
	text-decoration: none;
}

.MicroCopy,
.SmallCopy {
	font-size: 0.8em;
}

.MicroCopy {
	@include bp(medium) {
		font-size: 0.666667em;
	}
	@include bp(larger) {
		font-size: 0.6em;
	}
}

.Italic {
	font-style: italic;
}

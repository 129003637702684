.Hero {
	position: relative;
	display: block;
	margin-left: scale(0.5em);
}

.Hero-body {
	position: relative;
	z-index: 2;
	margin-top: scale(-0.25em);
	box-shadow: scale(-0.066666em) scale(-0.066666em) scale(0.166666em) rgba(0,0,0,0.4);
	@include bp(medium) {
		margin-top: scale(-0.3124980469em);
	}
	@include bp(larger) {
		margin-top: scale(-0.374998125em);
	}
}

.Hero-heading {
	position: relative;
	z-index: 0;
	@include bp(small) {
		margin-left: scale(-0.25em);
	}
}

.Hero-next {
	display: flex;
	position: relative;
	z-index: 1;
	float: right;
	margin-left: auto;

	.Button--major {
		&:hover {
			color: $grey-color;
		}
	}
}

.Pagination {
	@include clearfix;
	display: inline-block;
}

.Pagination-item {
	display: block;
	float: left;
}

.Pagination-index {
	padding: scale(0.25em, 0.8) scale(0.3em, 0.8) scale(0.45em, 0.8) 0;
	font-size: 0.8em;
	text-align: right;
	width: scale(2em, 0.8);
}

.Button {
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
	color: inherit;
	background-color: inherit;
	// Reset unusual Firefox-on-Android default style;
	// see https://github.com/necolas/normalize.css/issues/214
	background-image: none;
	border: none;
	white-space: nowrap;

	padding: scale(0.4375em, 0.8);
	height: scale(1.5em, 0.8);

	font-family: inherit;
	font-size: 0.8em;
	font-weight: 700;
	line-height: 1;

	user-select: none;
	transition-duration: 280ms;
	transition-property: background-color;
	transition-timing-function: cubic-bezier(0.4,0,0.2,1);
	-webkit-tap-highlight-color: rgba(0,0,0,0);

	&,
	&:active,
	&:hover,
	&:focus,
	&:visited {
		outline: 0;
		text-decoration: none;
	}

	&:active,
	&:focus {
		background-color: $secondary-color;
	}

	&:active {
		transition-duration: 0ms;
	}

	&.is-disabled,
	&[disabled],
	fieldset[disabled] & {
		color: rgb(168,168,168);
		cursor: not-allowed;
		// Future-proof disabling of clicks
		pointer-events: none;
		box-shadow: none;
	}
}

.Button--major {
	padding: scale(0.0625em);
	height: scale(0.875em);
	font-size: 1.5em;
	transition-property: color;
	@include bp(medium) {
		font-size: 1.75em;
	}
	@include bp(larger) {
		font-size: 2em;
	}

	&:active,
	&:focus {
		background-color: inherit;
		color: $grey-color;
	}
}

.Nav {
	padding-left: scale(0.5em);
	@include bp(small) {
		padding-left: scale(0.25em);
	}
}

.Nav-body {
	text-align: right;
}

.Nav-items {
	@include clearfix;
	margin: 0;
	padding: 0;
	display: inline-block;
	vertical-align: top;
}

.Nav-item {
	margin-right: scale(0.5em);
	display: block;
	list-style: none;
	float: left;

	&:last-child {
		margin-right: 0;
	}
}

.Nav-label {
	display: block;
	padding-left: scale(0.5em, 0.8);
	font-size: 0.8em;
	line-height: scale(1, 0.8);
	letter-spacing: 0.05em;
	text-transform: uppercase;
	@include bp(medium) {
		padding-top: scale(0.25em, 0.666667);
		padding-left: scale(0.25em, 0.666667);
		padding-bottom: scale(0.25em, 0.666667);
		font-size: 0.666667em;
		line-height: scale(0.9375);
	}
	@include bp(larger) {
		padding-top: scale(0.15em, 0.6);
		padding-left: scale(0.25em, 0.6);
		padding-bottom: scale(0.25em, 0.6);
		font-size: 0.6em;
		line-height: scale(1);
	}

	&::before {
		content: ">";
		display: block;
		float: left;
		margin-left: scale(-0.5em, 0.8);
		@include bp(medium) {
			margin-left: scale(-0.25em, 0.666667);
		}
		@include bp(larger) {
			margin-left: scale(-0.25em, 0.6);
		}
	}

	&:focus,
	&:active {
		outline: 0;
	}


	.Nav-item.isActive &,
	&:active,
	&:hover {
		color: $primary-color;
	}
}

.Nav--meta {
	color: $grey-color;
}

.Nav--listing {
	padding-left: scale(0.85em);
	color: $grey-color;

	.Nav-label {
		padding-left: 0;

		&:active,
		&:hover {
			color: inherit;
		}

		&::before {
			content: "";
			display: none;
		}
	}
}

.LinkList {
    margin-left: scale(0.5em);

    * + & {
        margin-top: scale(0.75em);
    }
}

.LinkList-item {
    @include bp(small) {
        margin-left: scale(-0.25em);
        padding-left: 0;
        padding-right: scale(0.25em);
    }

    &.LinkList-item--separator {
        position: relative;
        padding-bottom: scale(0.5em);
        margin-bottom: scale(0.5em) !important;

        &::after {
            position: absolute;
            bottom: 0;
            left: scale(0.1em);
            right: 0;
            border-top: 1px solid;
            content: "";
            @include bp(small) {
                left: scale(0.1em);
            }
        }
    }
}

.LinkList-item--article {
    .Link--category.SmallCopy {
        position: static;
        margin-left: 0.2em;
    }
}

.Billboard {
	position: relative;
	border-top: 1px solid $secondary-color;
}

.Billboard-visual {
	position: relative;
	z-index: 0;
}

.Billboard-body {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	padding-bottom: scale(0.25em);

	.Billboard--top & {
		top: 0;
		padding-top: scale(0.25em);
		padding-bottom: 0;
	}

	.Billboard-synopsis {
		display: inline-block;
		background: $secondary-color;
		line-height: 1;
		padding-bottom: 2px;
		margin-left: scale(-0.45em);
		padding-left: scale(0.45em);
		padding-right: scale(0.45em);

		@include bp(small) {
			padding-bottom: 3px;
		}
	}
}

.Billboard--dark {
	background-color: $primary-color;
	color: rgb(255,255,255);
}

a.Hero {
	&:hover {
		.Billboard-body {
			color: #000;
			background-color: rgba(216,255,0,0.8);
		}
	}
}

.Logo {
	@include font-titling;
	position: relative;
	display: inline-block;
	vertical-align: top;
	padding-left: 0.075em;
	font-size: 1.5em;
	outline: 0;
	transition-duration: 280ms;
	transition-property: color;
	transition-timing-function: cubic-bezier(0.4,0,0.2,1);
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	@include bp(medium) {
		font-size: 1.75em;
	}
	@include bp(larger) {
		font-size: 2em;
	}

	&:hover {
		outline: 0;
		color: $grey-color;
	}

	&:active {
		transition-duration: 0ms;
	}

	> svg {
		display: block;
		height: scale(0.875em);
		width: scale(4.4166667em);

		path {
			fill: currentColor;
		}
	}
}

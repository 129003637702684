.Heading {

}

.Heading-primary,
.Heading-secondary {
	text-transform: uppercase;

	a {
		&:hover {
			color: $grey-color;
		}
	}
}

.Heading-secondary {

	.Heading-primary + & {
		margin-top: scale(0.25em);
	}
}

.Heading--major {

	.Heading-primary,
	.Heading-secondary {
		@include font-titling;
		font-size: 1.5em;
		line-height: scale(0.875);
		@include bp(medium) {
			font-size: 1.75em;
		}
		@include bp(larger) {
			font-size: 2em;
		}
	}
}

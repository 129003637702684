.ResponsiveMedia,
.ResponsiveMedia-body {
	display: block;
	width: 100%;
	height: auto;
}

.ResponsiveMedia--cropped,
.ResponsiveMedia--fitted,
.ResponsiveMedia--full,
.ResponsiveMedia--scaled {
	position: relative;
	width: auto;

	.ResponsiveMedia-body {
		position: absolute;
		display: block;
	}
}

.ResponsiveMedia--cropped,
.ResponsiveMedia--fitted {
	padding-bottom: unit(9, 16);

	&.ResponsiveMedia--small {
		padding-bottom: unit(1, 3);
	}

	&.ResponsiveMedia--large {
		padding-bottom: unit(2, 3);
	}
}

.ResponsiveMedia--cropped {
	overflow: hidden;

	.ResponsiveMedia-body {
		top: 0;
		left: 0;
	}

	.ResponsiveMedia-body--tall {
		height: auto;
		width: 100%;
	}

	.ResponsiveMedia-body--wide {
		height: 100%;
		width: auto;
	}
}

.ResponsiveMedia--fitted,
.ResponsiveMedia--full
.ResponsiveMedia--scaled {

	.ResponsiveMedia-body {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

.ResponsiveMedia--fitted {

	.ResponsiveMedia-body--tall {
		margin: 0 auto;
		height: 100%;
		width: auto;
	}

	.ResponsiveMedia-body--wide {
		margin: auto 0;
		height: auto;
		width: 100%;
	}

	&.ResponsiveMedia--left .ResponsiveMedia-body--tall {
		margin-left: 0;
	}

	&.ResponsiveMedia--right .ResponsiveMedia-body--tall {
		margin-right: 0;
	}
}

.ResponsiveMedia--video {
	position: relative;
	overflow: hidden;

	> video,
	.ResponsiveMedia-body {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		display: block;
	}
}

.RichText {
	font-style: italic;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		text-transform: uppercase;

		& + * {
			margin-top: 0;
		}
	}

	p {
		hyphens: auto;
	}

	dl {
		margin: 0;

		> * + dt {
			margin-top: scale(0.5em);
		}
	}

	dt {

	}

	dd {
		margin-left: 0;
	}

	small {
		display: block;
		font-size: 0.8em;
		@include bp(medium) {
			font-size: 0.666667em;
		}
		@include bp(larger) {
			font-size: 0.633333em;
		}
	}

	a {
		background-image: linear-gradient(to bottom, rgba(255,255,255,0) unit(23, 24), $primary-color unit(23, 24));
		text-decoration: none;
		transition-duration: 280ms;
		transition-property: background-color;
		transition-timing-function: cubic-bezier(0.4,0,0.2,1);
		-webkit-tap-highlight-color: rgba(0,0,0,0);

		&:focus,
		&:active {
			outline: 0;
			background-color: $secondary-color;
		}

		&:active {
			transition-duration: 0ms;
		}
	}

	> * + * {
		margin-top: scale(0.5em);
	}
}

.RichText--small,
.RichText--micro {
	font-size: 0.8em;
}

.RichText--micro {
	font-size: 0.666667em;
	line-height: 1;
	@include bp(larger) {
		font-size: 0.566666em;
		line-height: 1.1111111111;
	}
}

.Footer {
	
}

.Footer-body {
	@include bp(small) {
		@include clearfix;
	}
}

.Footer-contact {
	padding: scale(0.25em) scale(0.5em) scale(0.5em);
	@include bp(small) {
		width: unit(2, 3);
		float: left;
	}
}

.Footer-nav {
	padding-right: scale(0.5em);
	padding-bottom: scale(0.25em);
	@include bp(small) {
		padding-left: scale(0.5em);
		width: unit(1, 3);
		float: left;
		text-align: right;
	}	
}

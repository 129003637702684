@font-face {
	font-family: "NationalWeb";
	font-style: normal;
	font-weight: 300;
	//src: url("assets/webfonts/NationalWeb-Light.eot");
	//src: url("assets/webfonts/NationalWeb-Light.eot?#iefix") format("embedded-opentype"),
	src: url("assets/webfonts/NationalWeb-Light.woff") format("woff");
}

@font-face {
	font-family: "NationalWeb";
	font-style: italic;
	font-weight: 300;
	//src: url("assets/webfonts/NationalWeb-LightItalic.eot");
	//src: url("assets/webfonts/NationalWeb-LightItalic.eot?#iefix") format("embedded-opentype"),
	src: url("assets/webfonts/NationalWeb-LightItalic.woff") format("woff");
}

@font-face {
	font-family: "NationalWeb";
	font-style: normal;
	font-weight: 400;
	//src: url("assets/webfonts/NationalWeb-Book.eot");
	//src: url("assets/webfonts/NationalWeb-Book.eot?#iefix") format("embedded-opentype"),
	src: url("assets/webfonts/NationalWeb-Book.woff") format("woff");
}

@font-face {
	font-family: "NationalWeb";
	font-style: italic;
	font-weight: 400;
	//src: url("assets/webfonts/NationalWeb-BookItalic.eot");
	//src: url("assets/webfonts/NationalWeb-BookItalic.eot?#iefix") format("embedded-opentype"),
	src: url("assets/webfonts/NationalWeb-BookItalic.woff") format("woff");
}

@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: url('assets/webfonts/roboto-v20-latin-100.woff2') format('woff2'),
	url('assets/webfonts/roboto-v20-latin-100.woff') format('woff');
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url('assets/webfonts/roboto-v30-latin-500.woff2') format('woff2'),
  url('assets/webfonts/roboto-v30-latin-500.woff') format('woff');
}

html {
	height: 100%;
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

::-moz-selection {
	color: $primary-color;
	background-color: $secondary-color;
	text-shadow: none;
}

::selection {
	color: $primary-color;
	background-color: $secondary-color;
	text-shadow: none;
}

body {
	height: 100%;
}

.Slideshow {
	@include clearfix;
	position: relative;
}

.Slideshow-slides {
	position: relative;
	z-index: 0;
	overflow: hidden;
	white-space: nowrap;
	letter-spacing: -0.31em; /* webkit: collapse white-space between units */
	text-rendering: optimizespeed; /* Webkit: fixes text-rendering: optimizeLegibility */

	/* Opera as of 12 on Windows needs word-spacing.
	   The ".opera-only" selector is used to prevent actual prefocus styling
	   and is not required in markup.
	*/
	.opera-only :-o-prefocus,
	& {
		word-spacing: -0.43em;
	}
}

.Slideshow-slide {
	/* Fixes bug in Safari on Mac OS and iOS */
	min-height: scale(1em);

	.Slideshow-slides > & {
		position: relative;
		display: inline-block;
		letter-spacing: normal;
		word-spacing: normal;
		vertical-align: top;
		text-rendering: auto;
		width: 100%;
		white-space: normal;
		box-sizing: border-box;
		z-index: 0;

		&.isActive {
			z-index: 1;
		}
	}
}

.Slideshow-caption {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: scale(0.25em) 0;
}

.Slideshow-pagination {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	padding: 0 scale(0.1875em);
	line-height: scale(1em, $scale-base);
	z-index: 1;
}

$use-mediaqueries: true !default;

$min-width: (
	smaller: 20.0625em,
	small: 30em,
	medium: 37.5625em,
	large: 60.375em,
	larger: 90.0625em
);

$max-width: (
	smaller: 20em,
	small: 29.9375em,
	medium: 37.5em,
	large: 60.3125em,
	larger: 90em
);

@mixin bp($min, $max: null) {
	$min-value: map-get($min-width, $min);
	$max-value: map-get($max-width, $max);

	@if $min-value {
		@if $max-value {
			@if $use-mediaqueries {
				@media (min-width: $min-value) and (max-width: $max-value) { @content; }
			}
			@else if $max-value > map-get($max-width, large) {
				@content;
			}
		}
		@else {
			@if $use-mediaqueries {
				@media (min-width: $min-value) { @content; }
			}
			@else if $min-value <= map-get($min-width, large) {
				@content;
			}
		}
	}
	@else if $max-value {
		@if $use-mediaqueries {
			@media (max-width: $max-value) { @content; }
		}
		@else if $max-value > map-get($max-width, large) {
			@content;
		}
	}
}

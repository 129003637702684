// Scale
$scale-base: 1.33334;

// Colors
$primary-color: rgb(34,34,34);
$secondary-color: rgb(216,255,0);
$grey-color: rgb(170,170,170);
$light-grey-color: rgb(239,242,251);

// Mixins
@import "mixins/all";

// Vendor
@import "vendor/normalize";

// Globals
@import "globals/animations";
@import "globals/reset";
@import "globals/colors";
@import "globals/typography";

// Objects
@import "modules/logo";
@import "modules/heading";
@import "modules/label";
@import "modules/link";
@import "modules/button";
@import "modules/rich-text";
@import "modules/caption";
@import "modules/responsive-media";
@import "modules/billboard";
@import "modules/poster";
@import "modules/card";
@import "modules/icon";
@import "modules/formatted-number";
@import "modules/pagination";
@import "modules/dots";
@import "modules/loading-indicator";

// Modules
@import "modules/menu";
@import "modules/nav";
@import "modules/masthead";
@import "modules/header";
@import "modules/figure";
@import "modules/text-block";
@import "modules/list";
@import "modules/link-list";
@import "modules/hero";
@import "modules/slideshow";
@import "modules/slide";
@import "modules/audio-player";

// Layouts
@import "modules/application";
@import "modules/block";
@import "modules/content";
@import "modules/article";
@import "modules/footer";
@import "modules/page";

// Themes
@import "themes/kulturwandel";

@import "vendor/mediaelementplayer";

.Figure {

}

.Figure-caption {
	padding: scale(0.25em) 0;
}

.Figure--dark {
	color: rgb(255,255,255);
}

.Figure--fitted {

	.Figure-visual {
		padding: scale(0.375em);
	}

	.Figure-caption {
		padding-top: 0;
	}

	&.Figure--top {
		display: flex;
		flex-direction: column-reverse;

		.Figure-caption {
			padding-top: scale(0.25em);
			padding-bottom: 0;
		}
	}
}

.Figure--full {
	position: relative;

	.Figure-visual {
		position: relative;
		z-index: 0;
	}

	.Figure-caption {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}

	&.Figure--top {

		.Figure-caption {
			top: 0;
			bottom: auto;
		}
	}
}

.List {
	@include clearfix;
}

.List-item {
	float: left;
	width: 100%;
}

.List--small {

	.List-item {
		margin-top: scale(1em);
		width: unit(1, 2);
		@include bp(small) {
			width: unit(1, 3);
		}
		@include bp(large) {
			width: unit(1, 4);
		}

		&:nth-child(2n+1) {
			@include bp(null, small) {
				clear: left;
			}
		}

		&:nth-child(3n+1) {
			@include bp(small, large) {
				clear: left;
			}
		}

		&:nth-child(4n+1) {
			@include bp(large) {
				clear: left;
			}
		}
	}
}

.List--medium {

	.List-item {
		@include bp(small) {
			margin-top: scale(1em);
			width: unit(1, 2);
		}

		&:nth-child(2n+1) {
			@include bp(small) {
				clear: left;
			}
		}
	}

	.List-item + .List-item {
		@include bp(null, small) {
			margin-top: scale(0.5em);
		}
	}
}

.List--large {

	.List-item {
		width: 100%;
	}

	.List-item + .List-item {
		margin-top: scale(0.5em);
	}
}

.List--mixed {
	display: flex;
	flex-wrap: wrap;

	&::after,
	&::before {
		content: "";
		display: none;
	}

	.Page-relatedMedia & {
		padding-top: scale(0.25em);
	}

	.List-item {
		margin-bottom: scale(1em);
		flex: 0 0 100%;
		width: auto;
	}

	.List-item--small {
		flex: 0 0 50%;
		@include bp(small) {
			flex: 0 0 25%;
		}
	}

	.List-item--medium {
		flex: 0 0 100%;
		@include bp(small) {
			flex: 0 0 50%;
		}
	}
}

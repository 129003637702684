.Application {

	&.slanted::after {
		content: "";
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		right: 25%;
		padding-bottom: 125%;
		border-right: 1px solid $secondary-color;
		transform-origin: right top;
		transform: rotate(45deg);
		@include bp(small) {
			right: 33.33333%;
		}
		@include bp(medium) {
			right: 50%;
		}
		@include bp(large) {
			right: 66.66666%;
		}
	}
}

.Application-masthead,
.Application-main,
.Application-menu {
	position: relative;
}

.Application-masthead {
	z-index: 2;
}

.Application-menu {
	z-index: 3;
}

.Application-main {
	z-index: 1;
}

.Application-loading {
	display: none;
	position: fixed;
	z-index: 2;
	top: scale(0.25em);
	left: scale(0.25em);
	border-radius: 50%;
	padding: scale(0.25em);
	line-height: scale(0.5);
	color: $secondary-color;
	background-color: rgba(255,255,255,0.8);
	@include bp(small) {
		top: scale(0.66666em);
		left: 50%;
		margin-left: scale(-0.25em);
	}

	.Application.isLoading & {
		display: block;
	}
}

.Poster {
	position: relative;
	padding-bottom: unit(9, 16);
}

.Poster-image {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}

.Poster-image--top-right {
	background-position: right top;
}

.Poster-image--top-center {
	background-position: center top;
}

.Poster-image--top-left {
	background-position: left top;
}

.Poster-image--center-right {
	background-position: right center;
}

.Poster-image--center-center {
	background-position: center center;
}

.Poster-image--center-left {
	background-position: left center;
}

.Poster-image--bottom-right {
	background-position: right bottom;
}

.Poster-image--bottom-center {
	background-position: center bottom;
}

.Poster-image--bottom-left {
	background-position: left bottom;
}

.Poster--large {
	padding-bottom: unit(2, 3);
}

.Poster--small {
	padding-bottom: unit(1, 3);
}

.Poster--full {
	padding-bottom: 0;
	width: 100%;
	height: 100%;
}

.LoadingIndicator {
	border-radius: 50%;
	animation: spin 0.5s infinite linear;
	border-width: 0.05555555556em;
	border-style: solid;
	border-right-color: transparent;
	border-top-color: transparent;
	width: scale(0.5em);
	height: scale(0.5em);
	display: inline-block;
	vertical-align: top;
	@include bp(medium) {
		border-width: 0.09090909091em;
	}
}

.Page {

}

.Page-article,
.Page-categories,
.Page-details,
.Page-feature,
.Page-footer,
.Page-intro,
.Page-caseStudies,
.Page-relatedCaseStudy,
.Page-relatedMedia {

	* + & {
		margin-top: scale(0.5em);
	}

	.Page-header + & {
		margin-top: scale(1em);
	}

	.Page--single .Page-header + & {
		position: relative;
		margin-top: scale(-0.2916654514em);
		@include bp(medium) {
			margin-top: scale(-0.3124980469em);
		}
		@include bp(larger) {
			margin-top: scale(-0.374998125em);
		}
	}
}

.Page-header {

	* + & {
		margin-top: scale(0.5em);
	}

	& + .Page-intro {
		position: relative;
		margin-top: scale(-0.2916654514em);
		@include bp(medium) {
			margin-top: scale(-0.3124980469em);
		}
		@include bp(larger) {
			margin-top: scale(-0.374998125em);
		}
	}
}

.Page-relatedCaseStudy,
.Page-relatedMedia {

	* + & {
		margin-top: scale(3em);
	}
}

.Page-relatedCaseStudy + .Page-relatedMedia,
.Page-relatedMedia + .Page-relatedCaseStudy {
	margin-top: scale(1.5em);
}

.Page-nav + .Page-article {
	margin-top: 0;
}

.Page-footer,
.Page-relatedCaseStudy,
.Page-relatedMedia {
	position: relative;
	overflow: hidden;

	&::before {
		position: absolute;
		top: 0;
		left: scale(0.5em);
		right: -50%;
		border-top: 1px solid;
		content: "";
	}
}

.Page-caseStudies {
	padding-bottom: scale(0.5em);

	.Page-relatedMedia + & {
		position: relative;
		margin-top: scale(-0.25em);
	}

	.Page-categories + & {
		position: relative;
		padding-top: scale(0.5em);

		&::before {
			position: absolute;
			top: 0;
			left: scale(0.5em);
			right: 0;
			border-top: 1px solid;
			content: "";
			@include bp(small) {
				left: scale(0.375em);
			}
		}

		.LinkList-item {
			&:first-child:not(:last-child) {
				margin-bottom: scale(1em);
			}
		}
	}
}

.Page-relatedCaseStudy,
.Page-relatedMedia {
	padding-top: scale(0.25em);
}

.Page-about {
	margin-top: scale(1em);
}

.Page--category {
	.Page-categories {
		margin-top: scale(0.5em);
	}
}


.Page--category,
.Page--article,
.Page--caseStudy {
	.Heading-secondary {
		margin-top: scale(0.66em);

		@include bp(medium) {
			margin-top: scale(0.57em);
		}

		@include bp(larger) {
			margin-top: scale(0.5em);
		}
	}
}

.Page--home {

	.Page-header {
		.Heading-primary {
			display: inline;
			background: $secondary-color;

			@include bp(small) {
				margin-left: scale(-0.25em);
				padding-left: scale(0.25em);
				padding-right: scale(0.25em);
			}
		}
	}

	.Page-categories {
		position: relative;
		padding-top: scale(0.5em);
		margin-top: scale(0.5em);

		&::before {
			position: absolute;
			top: 0;
			left: scale(0.5em);
			right: 0;
			border-top: 1px solid;
			content: "";
			@include bp(small) {
				left: scale(0.375em);
			}
		}

		.LinkList-item {
			&.LinkList-item--line {
				position: relative;
				padding-top: scale(0.5em);
				margin-top: scale(0.5em);

				&::before {
					position: absolute;
					top: 0;
					left: scale(0.1em);
					right: 0;
					border-top: 1px solid;
					content: "";
					@include bp(small) {
						left: scale(0.1em);
					}
				}
			}

          &.LinkList-item--separator {
            position: relative;
            padding-bottom: scale(0.5em);
            margin-bottom: scale(0.5em) !important;

            &::after {
              position: absolute;
              bottom: 0;
              left: scale(0.1em);
              right: 0;
              border-top: 1px solid;
              content: "";
              @include bp(small) {
                left: scale(0.1em);
              }
            }
          }
		}

		.Hero {
			margin-left: 0;
			margin-right: 0;
			margin-bottom: scale(0.375em);

			@include bp(small) {
				margin-left: .16em;
				margin-right: -0.16em;
			}

			.Billboard-body {
				.Content {
					opacity: 0;
				}
			}

			&:hover {
				.Billboard-body {
					color: #000;
					background-color: rgba(216,255,0,0.8);

					.Content {
						opacity: 1;
					}
				}
			}
		}
	}

	.Page-feature {
		margin-top: scale(0.75em);
	}

	.Page-caseStudies {
		position: relative;
		padding-top: scale(0.5em);
		padding-bottom: 0;
		margin-top: scale(2.5em);

		&::before {
			position: absolute;
			top: 0;
			left: scale(0.5em);
			right: 0;
			height: 3px;
			border-top: 1px solid $secondary-color;
			border-bottom: 1px solid $secondary-color;
			background: #000;
			content: "";
			@include bp(small) {
				left: scale(0.375em);
			}
		}

		.Hero-body {
			@include bp(small) {
				margin-left: -0.14em;
				margin-right: -.14em;
			}
		}
	}

	.Page-about {
		.Hero {
			&:hover {
				.Hero-heading {
					color: $grey-color;
				}
			}
		}
	}
}

.Page--medium {

	.Page-header + .Page-article {
		position: relative;
		margin-top: scale(-0.1666666667em);
		@include bp(medium) {
			margin-top: scale(-0.15625em);
		}
		@include bp(larger) {
			margin-top: scale(-0.175em);
		}
	}
}

.Page-caseStudies {
	.Billboard {
		.Billboard-body {
			.SmallCopy {
				em {
					display: none;
				}
			}
		}
	}
}

.Card {
	position: relative;
	margin: 0 scale(0.5em);
	display: block;
	box-shadow: scale(-0.066666em) scale(-0.066666em) scale(0.166666em) rgba(0,0,0,0.4);
}

a.Card {

	&:focus,
	&:active {
		outline: 0;
	}

	.Card-body {
		opacity: 0;
	}

	&:hover .Card-body {
		opacity: 1;
	}
}

.Card-visual {
	position: relative;
	z-index: 0;
}

.Card-body {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 2;
	padding: scale(0.25em) scale(0.375em) 0;
	background-color: rgba(216,255,0,0.8);
	hyphens: auto;
}

.Card-heading {
	padding: scale(0.25em);
	@include bp(small) {
		margin-left: scale(-0.25em);
		padding-left: 0;
	}
}
